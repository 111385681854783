/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ImgLA10 = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "portfolio/the-old-mill/catharine-ann-farnen-landscape-architecture-portfolio-the-old-mill-3-pennsylvania.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img css={css`height: 100%; width: 100%;`} alt='the old mill landscape architecture' fluid={data.placeholderImage.childImageSharp.fluid} />}
  />
)
export default ImgLA10
