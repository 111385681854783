/** @jsx jsx */
import { css, jsx } from '@emotion/core'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const ImgLA6 = () => (
  <StaticQuery
    query={graphql`
      query {
        placeholderImage: file(relativePath: { eq: "portfolio/pipersville-meadow/catharine-ann-farnen-landscape-architecture-portfolio-pipersville-meadow-1-pennsylvania.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img css={css`height: 100%; width: 100%;`} alt='pipersville meadow landscape architecture' fluid={data.placeholderImage.childImageSharp.fluid} />}
  />
)
export default ImgLA6
