import React from 'react'
/** @jsx jsx */
import { css, jsx, Global } from '@emotion/core'
import styled from '@emotion/styled'

// Components
import SEO from '../components/organisms/seo'
import Layout from "../components/layout"
import Masthead from "../components/organisms/masthead"
import Header1 from "../components/atoms/header-1"
import Paragraph1 from "../components/atoms/paragraph-1"
import Link4 from "../components/atoms/link-4"

// Images
import ImgMastheadLA from "../components/images/masthead/img-masthead-la";
import ImgTagLA from "../components/images/tag/img-tag-la";
import ImgLA1 from "../components/images/_landscape-architecture/img-la-1";
import ImgLA2 from "../components/images/_landscape-architecture/img-la-2";
import ImgLA3 from "../components/images/_landscape-architecture/img-la-3";
import ImgLA4 from "../components/images/_landscape-architecture/img-la-4";
import ImgLA5 from "../components/images/_landscape-architecture/img-la-5";
import ImgLA6 from "../components/images/_landscape-architecture/img-la-6";
import ImgLA7 from "../components/images/_landscape-architecture/img-la-7";
import ImgLA8 from "../components/images/_landscape-architecture/img-la-8";
import ImgLA9 from "../components/images/_landscape-architecture/img-la-9";
import ImgLA10 from "../components/images/_landscape-architecture/img-la-10";

// Styles
const Container = styled.section`
  position: relative;
  width: auto;
  max-width: 1440px;
  padding: 0 30px;
  margin: 150px auto 175px auto;
  &:before {
    content: " ";
    display: table;
  }
  &:after {
    clear: both;
    content: " ";
    display: table;
  }
  @media (max-width: 992px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

  }
  @media (max-width: 500px) {
    padding: 0;
  }
`
const ContainerGallery = styled.section`
  padding: 0 30px;
  float: left;
  width: 45%;
  @media (max-width: 1300px) {
    width: 42%
  }
  @media (max-width: 992px) {
    float: none;
    order: 2;
    width: calc(100% - 60px);
  }
  @media (max-width: 500px) {
    padding: 0 15px;
  }
`
const ContainerImage = styled.div`
  margin-bottom: 30px;
  &:last-of-type {
    margin: 0;
  }
`
const ContainerOverview = styled.section`
  position: sticky;
  float: left;
  top: 10vw;
  padding: 0 30px;
  width: 45%;
  max-width: 550px;
  @media (max-width: 992px) {
    position: relative;
    float: none;
    top: 0;
    width: 100%;
    margin: 0 auto 150px auto;
    text-align: center;
  }
`
const ContainerSmoosh = styled.section`
  padding: 0 60px;
  @media (max-width: 992px) {
    padding: 0;
  }
`
// Data
const dataMasthead = {
  mastheadImage: <ImgMastheadLA/>,
  tagImage: <ImgTagLA/>,
  tagTitle: 'Landscape Architecture',
}
const pageData = {
  part1Images: [
    <ImgLA1/>,
    <ImgLA2/>,
    <ImgLA3/>,
    <ImgLA4/>,
    <ImgLA5/>,
  ],
  part1Title: 'Design Process',
  part1Text1: 'Meeting with the client to understand the intent and site context is the basis for determining the scope and terms of services. For some projects this requires collaboration with other design professionals.',
  part1Text2: 'Beginning with a survey, we will analyze existing features and focus on the intended use for the site.',
  part1Text3: 'Working through the design process our concepts are developed and presented based on input from the client and our understanding of the environment, for sustainable design solutions.',
  part2Images: [
    <ImgLA6/>,
    <ImgLA7/>,
    <ImgLA8/>,
    <ImgLA9/>,
    <ImgLA10/>,
  ],
  part2Title: 'Design Process',
  part2Text1: 'With client, agency and design team approvals, we move forward with documentation of plans, details and specifications.',
  part2Text2: 'These working documents allow others to take the design through the construction phase.',
  part2Text3: 'Our services include oversight and approval of layout, materials and details to assure a successful installation.',
}

class LandscapeArchitecturePage extends React.Component {
  render() {
    return (
      <Layout>

        <SEO title={'Design Process'} keywords={[`landscaping`, `landscape architecture`, `catharine ann farnen llc`, `pennsylvania landscaping`, `landscape design`, `design process`]} />
        
        {/* Fixes Position Sticky Issue on Netlify Deploy */}
        <Global
          styles={css`
            .tl-edges {
              overflow: visible;
            }
          `}
        />

        <Masthead 
          mastheadImage={dataMasthead.mastheadImage} 
          tagImage={dataMasthead.tagImage}
          tagTitle={dataMasthead.tagTitle}
          tagType={dataMasthead.tagType}
        />
        <Container>

          {/* Part 1 */}

          <ContainerGallery>
            {pageData.part1Images.map((item, i) => (
              <ContainerImage key={i}>
                {item}
              </ContainerImage>
            ))}
          </ContainerGallery>
          <ContainerOverview>
            <Header1>{pageData.part1Title}</Header1>
            <ContainerSmoosh>
              <Paragraph1 css={css`margin-bottom: 120px;`}>
                {pageData.part1Text1}
              </Paragraph1>
              <Paragraph1 css={css`margin-bottom: 120px;`}>
                {pageData.part1Text2}
              </Paragraph1>
              <Paragraph1 css={css`margin-bottom: 120px;`}>
                {pageData.part1Text3}
              </Paragraph1>
            </ContainerSmoosh>
          </ContainerOverview>
        </Container>

        {/* Part 2 */}

        <Container>
          <ContainerOverview>
            <Header1>{pageData.part2Title}</Header1>
            <ContainerSmoosh>
              <Paragraph1 css={css`margin-bottom: 120px;`}>
                {pageData.part2Text1}
              </Paragraph1>
              <Paragraph1 css={css`margin-bottom: 120px;`}>
                {pageData.part2Text2}
              </Paragraph1>
              <Paragraph1 css={css`margin-bottom: 120px;`}>
                {pageData.part2Text3}
              </Paragraph1>
              <Link4 to='/contact'>Request a Consultation</Link4>
            </ContainerSmoosh>
          </ContainerOverview>
          <ContainerGallery>
            {pageData.part2Images.map((item, i) => (
              <ContainerImage key={i}>
                {item}
              </ContainerImage>
            ))}
          </ContainerGallery>
        </Container>

      </Layout>
    )
  }
}

export default LandscapeArchitecturePage