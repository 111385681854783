/** @jsx jsx */
import { css, jsx } from '@emotion/core'

// Components
import { Link } from 'gatsby'

const Link4 = ({children, to}) => (
  <Link
    css={css`
      display: inline-block;
      padding: 20px 30px;
      background: #70926E;
      border: 1px solid #70926E;
      color: #FFFFFF;
      font-size: 2em;
      font-family: 'Poiret One';
      font-weight: 300;
      text-decoration: none;
      transition: 0.2s;
      &:hover {
        color: #70926E;
        background: #FFFFFF;
        border: 1px solid #70926E;
      }
    `}
    to={to}
  >
    {children}
  </Link>
)

export default Link4